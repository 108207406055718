import "./Intro.css";

import { Box, Link, chakra, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";

const Intro = ({ estimatorType }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	const [annexationAgreementUrl] = useState(process.env.REACT_APP_ANNEXATION_AGREEMENT_URL);
	const [taxRatesUrl] = useState(process.env.REACT_APP_TAX_RATES_URL);
	const [annexationLeducUrl] = useState(process.env.REACT_APP_ANNEXATION_LEDUC_URL);

	return (
		<React.Fragment>
			<Box
				p={3}
				mt={1}
				className='intro-container'>
				<Box maxW={isSmallerScreen ? 900 : 1000}>
					{(() => {
						switch (estimatorType) {
							case "sf":
								return (
									<chakra.span>
										The residential property assessment class includes single-family homes,
										condominiums, townhouses and other properties of three or fewer dwelling units.
									</chakra.span>
								);

							case "or":
								return (
									<chakra.span>
										The other residential property assessment class includes residential properties
										of four or more dwelling units on one legal title. Properties in this assessment
										class include apartment buildings, fourplexes, lowrises, highrises, townhouses
										and row houses.
									</chakra.span>
								);

							case "com":
								return (
									<chakra.span>
										{" "}
										The non-residential property assessment class includes properties used for
										commercial or industrial purposes – or any property not used as a residence.
										<Box mt={3}>
											Non-residential properties include commercial properties (retail, offices
											and shopping centres), industrial properties (industrial condos and
											warehouses) and land (agricultural, development and dual use, commercial and
											industrial).
										</Box>
									</chakra.span>
								);

							case "fl":
								return (
									<chakra.span>
										Farm land properties include properties used for farming operations.
									</chakra.span>
								);

							case "der":
								return (
									<chakra.span>
										The Mature Area Derelict Residential subclass includes residential properties in
										the City's mature neighbourhoods that show serious signs of neglect or
										dilapidation, are falling into significant disrepair or are uninhabitable.
									</chakra.span>
								);

							case "mu":
								return (
									<chakra.span>
										Your property might belong to different property assessment classes or be fully
										or partially exempt from paying property taxes. The City of Edmonton defines
										such properties as mixed use. The City then assesses each "portion" of the
										property according to the rules established for that property group and applies
										appropriate property tax rates.
									</chakra.span>
								);

							case "an":
								return (
									<chakra.span>
										According to the
										<Link
											href={annexationAgreementUrl}
											rel={"noopener"}
											target={"_blank"}>
											{" "}
											Annexation Agreement{" "}
										</Link>
										between Leduc County and the City of Edmonton, annexed properties are taxed at
										the lower rate between the County and the City up to and including 2069, or
										until certain conditions occur.
										<Box mt={3}>
											You are welcome to use our tool; however, please know this estimator
											provides estimates based on the City of Edmonton
											<Link
												href={taxRatesUrl}
												rel={"noopener"}
												target={"_blank"}>
												{" "}
												tax rates{" "}
											</Link>
											only.
										</Box>
										<Box mt={3}>
											If you wish to review Leduc County's tax rates, please visit
											<Link
												href={annexationLeducUrl}
												rel={"noopener"}
												target={"_blank"}>
												{" "}
												leduc-county.com{" "}
											</Link>
											.
										</Box>
									</chakra.span>
								);
							default:
								return null;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
};
export default Intro;
