import "./TaxEstimator.css";

import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Link,
	Select,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	chakra,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Calculator from "./Calculator/Calculator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Intro from "./Intro/Intro";
import { axiosMyPropertyAuth } from "../../../../../../apis";

const TaxEstimator = ({ accountDetails, accId }) => {
	const [historyMuniChangesUrl] = useState(process.env.REACT_APP_HISTORY_MUNI_CHANGES_URL);
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [estimatorTypes] = useState([
		{ id: "sf", text: "Residential", estimatorTaxClass: "residential" },
		{ id: "or", text: "Other Residential", estimatorTaxClass: "other residential" },
		{ id: "com", text: "Non-Residential", estimatorTaxClass: "non residential" },
		{ id: "fl", text: "Farmland", estimatorTaxClass: "farmland" },
		{ id: "der", text: "Derelict", estimatorTaxClass: "derelict" },
		{ id: "mu", text: "Mixed-use and Exempt" },
		{ id: "an", text: "Annexed" },
	]);
	const [estimatorValueTabIndex, setEstimatorValueTabIndex] = useState(0);
	const [hideSingleEstimatorTypes, setHideSingleEstimatorTypes] = useState(false);
	const [taxRates, setTaxRates] = useState([]);
	const [multiUseTaxRates, setMultiUseTaxRates] = useState([]);
	const [showEstimator, setShowEstimator] = useState(true);

	const toast = useToast();
	const taxEstimatorToastErrorId = "tax-estimator-toast-error";

	useEffect(() => {
		if (accountDetails !== undefined) {
			if (accountDetails.mayDecTaxEstimator === "Y") {
				setShowEstimator(false);
			} else {
				setShowEstimator(true);
				displayEstimatorType();
				getTaxRates();
				getMultiUseTaxRates();
			}
		}
	}, [accountDetails, accId]);

	const displayEstimatorType = () => {
		let indexToSelect;
		if (accountDetails.singleTaxClass.toLowerCase() === "true" && accountDetails.hasExemptions === "N") {
			let taxClass = accountDetails.estimatorTaxClass.toLowerCase();
			indexToSelect = estimatorTypes.map((x) => x.estimatorTaxClass).indexOf(taxClass);
			setEstimatorValueTabIndex(indexToSelect);
			setHideSingleEstimatorTypes(false);
		} else {
			indexToSelect = estimatorTypes.map((x) => x.id).indexOf("mu");
			setHideSingleEstimatorTypes(true);
		}
		setEstimatorValueTabIndex(indexToSelect);

		// set the dropdown selected value
		document.getElementById("estimatorTypeMobile").value = indexToSelect;
	};

	const handleEstimatorTabChange = (index) => {
		setEstimatorValueTabIndex(index);
	};

	const getTaxRates = () => {
		let url = `taxes/rates`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				setTaxRates(resp.data);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(taxEstimatorToastErrorId)) {
					toast(toastMessages.getToastError(taxEstimatorToastErrorId));
				}
			});
	};

	const getMultiUseTaxRates = () => {
		let url = `taxes/multiuserates/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				setMultiUseTaxRates(returnObj);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(taxEstimatorToastErrorId)) {
					toast(toastMessages.getToastError(taxEstimatorToastErrorId));
				}
			});
	};

	const handleCustomEstimatorValueTabChange = (e) => {
		e.preventDefault();
		//let index = parseInt(e.target.options.selectedIndex);
		let value = parseInt(e.target.value);
		if (value === -1) {
			// set state and mobile to index 0
			setEstimatorValueTabIndex(0);
			document.getElementById("estimatorTypeMobile").value = 0;
		} else {
			// set state to the selected index
			setEstimatorValueTabIndex(value);
		}
	};

	const updateTaxEstimatorValueDropdownSelection = (e) => {
		// called from a tab selection
		// set the mobile dropdown to the selected index
		e.preventDefault();
		let value = parseInt(e.target.value);
		let estimatorTypeMobile = document.getElementById("estimatorTypeMobile");

		if (value === -1) {
			estimatorTypeMobile.value = 0;
		} else {
			estimatorTypeMobile.value = value;
		}
	};

	return (
		<React.Fragment>
			<Box ml={3}>
				<Box
					mb={3}
					maxW={1050}>
					<chakra.span fontSize={"xl"}>
						{!showEstimator ? (
							<>
								{/* calculator */}
								The City's final tax rates have been set for the year and your current year tax levy is
								available above. <br />
								The tax estimator will be available again in January to estimate your taxes for the
								following year.
							</>
						) : (
							<>
								{/* estimator */}
								This property tax estimator can help you estimate your upcoming tax bill, based on your
								property's assessed value and your estimate of the percentage change in municipal taxes
								and provincial education taxes.
							</>
						)}
					</chakra.span>
				</Box>

				{showEstimator ? (
					<>
						<Box maxW={1050}>
							<Box
								w={"100%"}
								pl={2}
								mt={3}
								mb={4}>
								<Box w={"100%"}>
									<chakra.span fontWeight={700}>Step 1: </chakra.span>
									Confirm or adjust the assessment value of your property.
								</Box>
								<Box
									pt={3}
									w={"100%"}>
									<chakra.span fontWeight={700}>Step 2: </chakra.span>
									Adjust the municipal tax change and provincial education tax change—according to
									your best estimate. If you wish, you can view the{" "}
									<Link
										href={historyMuniChangesUrl}
										rel={"noopener"}
										target={"_blank"}>
										history of municipal and provincial education tax changes
									</Link>{" "}
									for reference.
								</Box>
								<Box
									pt={3}
									w={"100%"}>
									<chakra.span fontWeight={700}>Step 3: </chakra.span>
									Click Estimate Tax and review the estimate of your annual property tax bill.
								</Box>
							</Box>
						</Box>
						<Box display={isSmallerScreen ? "normal" : "none"}>
							<Box
								className='assessed-value-select-container'
								mb={1}>
								<Select
									id='estimatorTypeMobile'
									name='estimatorTypeMobile'
									className='estimator-value-select'
									onChange={(e) => {
										handleCustomEstimatorValueTabChange(e);
										updateTaxEstimatorValueDropdownSelection(e);
									}}
									iconSize={"1em"}
									icon={<FontAwesomeIcon icon='fa-solid fa-caret-down' />}>
									{estimatorTypes.map((item, index) => (
										<option
											key={index}
											value={index}>
											{item.text.trim()}
										</option>
									))}
								</Select>
							</Box>
						</Box>
						<Tabs
							className='estimator tabs'
							variant='unstyled'
							index={estimatorValueTabIndex}
							onChange={handleEstimatorTabChange}
							orientation={isSmallerScreen ? "vertical" : "horizontal"}>
							<Box className='estimator-display'>
								<TabList
									mb='1em'
									className='estimator tab-list'>
									{estimatorTypes.map((item, index) => (
										<Tab
											display={isSmallerScreen ? "none" : "normal"}
											key={index}
											value={index}
											_selected={{ color: "white", bg: "blue.500" }}
											_hover={{ color: "white", bg: "blue.500" }}
											className='estimator tab'
											hidden={hideSingleEstimatorTypes && index < 4}
											onClick={(e) => {
												updateTaxEstimatorValueDropdownSelection(e);
											}}>
											{item.text.trim()}
										</Tab>
									))}
								</TabList>
							</Box>
							<TabPanels>
								{estimatorTypes.map((item, index) => (
									<TabPanel
										className='estimator tab-panel'
										key={index}>
										<Box>
											<Box
												mt={1}
												w={"100%"}>
												<Intro
													estimatorType={estimatorTypes[estimatorValueTabIndex].id}></Intro>
											</Box>
										</Box>
										{estimatorTypes[estimatorValueTabIndex].id !== "an" ? (
											<Box mt={1}>
												<Calculator
													estimatorType={estimatorTypes[index]}
													accountDetails={accountDetails}
													taxRates={taxRates.webTaxRatesCol}
													taxYear={taxRates.taxYear}
													multiUseTaxRates={multiUseTaxRates}
													hideSingleEstimatorTypes={hideSingleEstimatorTypes}></Calculator>
											</Box>
										) : null}
									</TabPanel>
								))}
							</TabPanels>
						</Tabs>
					</>
				) : null}
			</Box>
		</React.Fragment>
	);
};
export default TaxEstimator;
